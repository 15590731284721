<template>
  <div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-form @submit.stop.prevent="handleSubmit(saveInvoice)" @reset.prevent="resetForm">
              <b-row>
                <b-col md="6">
                  <validation-provider
                      #default="validationContext"
                      :name="$t('First column')"
                      rules="required"
                  >
                    <b-form-group :label="$t('Footer first column')">
                      <b-form-textarea

                          rows="8"
                          v-model="currentItem.footer_col_1"
                          :placeholder="$t('First column')"
                      />
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                    <small v-if="validate && Object.keys(validation).includes('footer_col_1')" class="text-danger">{{ $t(validation.footer_col_1[0]) }}</small>
                    </b-form-group>
                  </validation-provider>

                </b-col>
                <b-col md="6">
                  <validation-provider
                      #default="validationContext"
                      :name="$t('Second column')"
                      rules="required"
                  >
                    <b-form-group :label="$t('Footer second column')">
                      <b-form-textarea
                          rows="8"
                          v-model="currentItem.footer_col_2"
                          :placeholder="$t('Second column')"
                      />
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                    <small v-if="validate && Object.keys(validation).includes('footer_col_2')" class="text-danger">{{ $t(validation.footer_col_2[0]) }}</small>
                    </b-form-group>
                  </validation-provider>

                </b-col>
                <b-col md="6">
                  <validation-provider
                      #default="validationContext"
                      :name="$t('Third column')"
                      rules="required"
                  >
                    <b-form-group :label="$t('Footer third column')">
                      <b-form-textarea
                          rows="8"
                          v-model="currentItem.footer_col_3"
                          :placeholder="$t('Third column')"
                      />
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                    <small v-if="validate && Object.keys(validation).includes('footer_col_3')" class="text-danger">{{ $t(validation.footer_col_3[0]) }}</small>
                    </b-form-group>
                  </validation-provider>

                </b-col>
                <b-col md="6">
                  <validation-provider
                      #default="validationContext"
                      :name="$t('Fourth column')"
                      rules="required"
                  >
                    <b-form-group :label="$t('Footer fourth column')">
                      <b-form-textarea
                          rows="8"
                          v-model="currentItem.footer_col_4"
                          :placeholder="$t('Fourth column')"
                      />
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                    <small v-if="validate && Object.keys(validation).includes('footer_col_4')" class="text-danger">{{ $t(validation.footer_col_4[0]) }}</small>
                    </b-form-group>
                  </validation-provider>

                </b-col>
                <b-col md="6">
                  <validation-provider
                      #default="validationContext"
                      :name="$t('Company Address')"
                      rules="required"
                  >
                    <b-form-group :label="$t('Company Address')" >
                      <b-form-textarea
                          rows="8"
                          v-model="currentItem.company_address"
                          :placeholder="$t('Company Address')"
                      />
                    <small class="text-danger">{{ validationContext.errors[0] }}</small>
                    <small v-if="validate && Object.keys(validation).includes('company_address')" class="text-danger">{{ $t(validation.company_address[0]) }}</small>
                    </b-form-group>
                  </validation-provider>

                </b-col>
              </b-row>
              <div class="d-flex mt-2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit">
                  {{ $t('Save') }}
                </b-button>
              </div>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormDatepicker,
  BFormRadioGroup,
  BFormTimepicker,
  BFormTextarea,
  BFormSelect,
  BFormCheckbox,

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { required, alphaNum, email } from '@validations'

export default {
  components: {
    quillEditor,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormRadioGroup,
    BFormTimepicker,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      currentItem: {
        footer_col_1: 'footer_col_1',
        footer_col_2: 'footer_col_2',
        footer_col_3: 'footer_col_3',
        footer_col_4: 'footer_col_4',
        company_address: 'company_address'
      },
      validation: {},
      validate: false,
    }
  },

  methods: {
    saveInvoice() {
    this.$refs.refFormObserver.validate()
  .then((success) => {
      this.validation = {};
      this.validate = false;
      if (success) {
      this.$http.post(`invoicesdata`, this.currentItem)
          .then(response => {
            this.$bvToast.toast(this.$i18n.t('Sections successfully saved'), {
              title: this.$i18n.t('Success'),
              variant: 'success',
              toaster: 'b-toaster-bottom-right'
            })
          }).catch(error => {
            if(error.response.status == 422){
                this.validation = error.response.data.errors;
                this.validate = true;
            } else {
                this.$bvToast.toast(error.response.data.message, {
                    title: this.$i18n.t('Error'),
                    variant: 'danger',
                    toaster: 'b-toaster-bottom-right'
                })
            }
          })
        }
          })
    },
    getInvoiceData() {
      this.$http.get(`invoicesdata`,)
          .then(response => {
            this.currentItem = response.data
          })
    },
    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },
  },
  mounted() {
    this.getInvoiceData()
  },
}
</script>

<style lang="scss" scoped>

</style>
